.homecontainer{
    background: rgb(121,198,202);
    background: radial-gradient(circle, rgb(127, 202, 206) 0%, rgba(93,149,152,1) 96%);      
}

@keyframes wave {
    0% {
      transform:  translateY(0);
    }
    25% {
      transform: translateY(5px);
    }
    50%{
        transform: translateY(0);
    }
    75% {
      transform:  translateY(-5px);
    }
    100% {
      transform: translateY(0);
    }
  }

.phone {
    filter: drop-shadow(-20px 20px 20px #00000058);
    animation: wave 8s ease-in-out infinite;

}
  




@keyframes shine {
  0% {
    background-size: 2000%;
    background-position: 100%;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  100% {
    background-size: 2000%;
    background-position: 0%;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
 
}

.myshine_green {
  background: linear-gradient(107deg, rgba(34,73,75,1) 0%, rgba(121,198,202,1) 6%, rgba(34,73,75,1) 11%);
  animation: shine 3s ease-in-out infinite; 
}

.myshine_gray{
  background: linear-gradient(107deg, rgb(130, 131, 131) 0%, rgb(255, 255, 255) 6%, rgb(126, 126, 126) 11%);
  animation: shine 3s ease-in-out infinite; 
}


.blurr {
  backdrop-filter: blur(20px); 
  -webkit-backdrop-filter: blur(20px);
}
