
@keyframes slide-l-r {
    0%{
        transform: translateX(-50px); 
        opacity: 0; 
    }
    100% {
        transform: translateX(0); 
        opacity: 1; 
      }
    
  }

  @keyframes slide-r-l {
    0%{
        transform: translateX(50px);
        opacity: 0; 
    }
    100% {
        transform: translateX(0); 
        opacity: 1; 
      }
    
  }


  
@keyframes rise {
    0% {
      transform: translateY(50px); 
      opacity: 0; 
    }
    100% {
      transform: translateY(0); 
      opacity: 1; 
    }
  }


  
@keyframes fall {
    0% {
      transform: translateY(-50px); 
      opacity: 0; 
    }
    100% {
      transform: translateY(0); 
      opacity: 1; 
    }
  }

  

  
  @keyframes fadein {
    0% {
      opacity: 0; 
      scale: 95%;
    }
    100% {
      scale: 100%;
      opacity: 1; 
    }
  }

.slide-animation-r-l {
    animation : slide-r-l 0.9s ease-out forwards;
}
.fadein-animation {
    animation : fadein 0.9s ease-out forwards;
}
.slide-animation-l-r {
    animation : slide-l-r 0.9s ease-out forwards;
}
.fall-animation {
    animation: fall 1s ease-out forwards; 
}
.rise-animation {
    animation: rise 1s ease-out forwards; 
}




.gradient_text{
  background: linear-gradient(107deg, rgb(0, 0, 0) 0%, rgb(14, 67, 70) 50%, rgb(0, 0, 0) 100%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}