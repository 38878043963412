
@tailwind base;
@tailwind components;
@tailwind utilities;


.poppins {
  font-family: "Poppins", serif;
  
  font-style: normal;
}

.montserrat {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight:600;
  font-style: normal;
}

body{
  overflow-x: hidden;
}

body::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Edge */
}

.carousel {
  scrollbar-width: none; 
}
